.App {
  text-align: right;
  direction: rtl !important;
  background-color: #f1f1f1;
  height: 100vh;
  font-family: Vazir !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
